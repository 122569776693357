import React, { useState } from "react";
import { Link } from "gatsby";
import Collapse from "react-bootstrap/Collapse";

import Button from "../custom-widgets/button";
import Icon from "../custom-widgets/icon";

// Styles
import styles from "./checking-overview.module.scss";

const BusinessCheckingOverview = () => {
  const [collapseClass1, setCollapseClass1] = useState(false);
  const [collapseClass2, setCollapseClass2] = useState(false);
  const [collapseClass3, setCollapseClass3] = useState(false);

  const locationsBtnData = {
    id: "cta-apply-at-branch",
    type: "link",
    containerClass: "mb-4 mb-md-0",
    class: "btn-primary w-100 w-sm-auto w-md-100",
    text: "Apply at Branch",
    url: "/locations",
    showIcon: false
  };

  const simpleBizCheckingBtn = {
    externalLink: true,
    id: "cta-simple-biz-checking",
    class: "btn-primary w-100 w-sm-auto w-md-100",
    containerClass: "mb-4 mb-md-0",
    text: "Open Account",
    url: "https://wafd.my.site.com/nPortal__SelfRegistration?core=es5&product=a0uUH000000dyHO",
    showIcon: false,
    target: "blank"
  };

  const bizInterestCheckingBtn = {
    externalLink: true,
    id: "cta-biz-interest-checking",
    class: "btn-primary w-100 w-sm-auto w-md-100",
    containerClass: "mb-4 mb-md-0",
    text: "Open Account",
    url: "https://wafd.my.site.com/nPortal__SelfRegistration?core=es5&product=a0uUH000000dyG3",
    showIcon: false,
    target: "blank"
  };

  return (
    <section id="business-checking-overview-section" className="container">
      {/* HIDDEN XS & SM */}
      <div className="d-none d-md-block">
        <div className="row">
          <div className="col">
            <span className="sr-only">Compare business checking account details table</span>

            {/* COMPARE CHECKING ACCOUNTS TABLE */}
            <table
              id="checking-overview-table"
              className={`table table-bordered table-fixed cols-3 ${styles.CheckingOverview}`}
            >
              <thead className="thead-white">
                <tr className="text-center">
                  {/* position: relative has been added prevent stretched-link from covering entire column */}
                  <th>
                    <Link
                      id="simple-business-link"
                      className="position-relative stretched-link text-decoration-none"
                      to="/business-banking/business-checking-account/free-business-checking"
                    >
                      <h4 className="mb-0 font-weight-bold">
                        *Free Business Checking <Icon name="arrow-right" class="ml-1" />
                      </h4>
                    </Link>
                  </th>
                  <th>
                    <Link
                      id="interest-checking-link"
                      className="position-relative stretched-link text-decoration-none"
                      to="/business-banking/business-checking-account/interest-checking"
                    >
                      <h4 className="mb-0 font-weight-bold">
                        Business Interest Checking <Icon name="arrow-right" class="ml-1" />
                      </h4>
                    </Link>
                  </th>
                  <th>
                    <h4 className="mb-0 font-weight-bold">Business Analyzed Checking</h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Button {...simpleBizCheckingBtn} />
                  </td>
                  <td>
                    <Button {...bizInterestCheckingBtn} />
                  </td>
                  <td>
                    <Button {...locationsBtnData} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Simple and direct, perfect for managing a small business.</p>
                    <p>Designed for businesses with lower monthly transactions and maintaining lower balances.</p>
                  </td>
                  <td>
                    <p>
                      This interest-bearing Business Checking Account is the right fit for clients with moderate
                      activity and balances.
                    </p>
                    <p className="text-gray-70">*Interest earned with balance above $1,000</p>
                  </td>
                  <td>
                    <p>
                      Analyzed Checking provides flexibility and control. Design a package tailored to your business
                      with the services you need.
                    </p>
                    See service options below and choose what's best for your business.
                  </td>
                </tr>
                <tr>
                  <td>$100 initial deposit</td>
                  <td>$100 initial deposit</td>
                  <td>$100 initial deposit</td>
                </tr>
                <tr>
                  <td>*Nonsufficient funds charge may apply.</td>
                  <td>$15 per month if balance falls below $5,000</td>
                  <td>Fee based on transaction volume. Use your account balance to offset fees.</td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    Please reach out to a{" "}
                    <Link to="/locations" id="locations-link">
                      branch
                    </Link>
                    ,{" "}
                    <Link id="business-banker-near-me-link" to="/business-banking/near-me">
                      Banking Officer
                    </Link>{" "}
                    or{" "}
                    <Link to="/commercial-banking/commercial-banker-near-me" id="commercial-banker-near-me-link">
                      Commercial Banker
                    </Link>{" "}
                    for additional information.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* VISIBLE XS & SM */}
      <div className="mb-3 d-block d-md-none">
        {/* Free Business Checking */}
        <article id="m-simple-business-checking" className="mb-3 border-bottom">
          <h2>
            <strong>*Free Business</strong> Checking
          </h2>
          <div className={`${styles.mobileDetailsItem} font-weight-bold`}>*Nonsufficient funds charge may apply.</div>
          <div
            id="business-checking-toggle"
            onClick={() => {
              setCollapseClass1(!collapseClass1);
            }}
            className={`${styles.mobileDetails} ${
              collapseClass1 ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-label="Show/Hide Details"
            aria-controls="business-checking-details"
            aria-expanded={collapseClass1}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapseClass1}>
            <div id="business-checking-details" className="collapse">
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>
                Simple and direct, perfect for managing a small business. Designed for businesses with lower monthly
                transactions and maintaining lower balances.
              </div>
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>$100 initial deposit</div>
              <div className={`${styles.mobileDetailsItem}`}>*Nonsufficient funds charge may apply.</div>
              <div className={`${styles.mobileDetailsItem} text-center`}>
                <Link to="/business-banking/business-checking-account/free-business-checking" className="btn btn-link">
                  Learn More
                </Link>
              </div>
            </div>
          </Collapse>
          <Button {...simpleBizCheckingBtn} />
        </article>

        {/* Stellar Business Interest Checking */}
        <article id="m-interest-checking" className="mb-3 border-bottom">
          <h2>
            <strong>Business</strong> Interest Checking
          </h2>
          <div className={`${styles.mobileDetailsItem} font-weight-bold`}>
            $15 per month if balance falls below $5,000
          </div>
          <div
            id="stellar-business-toggle"
            onClick={() => {
              setCollapseClass2(!collapseClass2);
            }}
            className={`${styles.mobileDetails} ${
              collapseClass2 ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-controls="stellar-business-details"
            aria-label="Show/Hide Details"
            aria-expanded={collapseClass2}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapseClass2}>
            <div id="stellar-business-details" className="collapse">
              <div className={`${styles.mobileDetailsItem}`}>
                <strong>
                  This interest-bearing Business Checking Account is the right fit for clients with moderate activity
                  and balances.
                </strong>
                <br />
                *Interest earned with balance above $1000
              </div>
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>$100 initial deposit</div>
              <div className={`${styles.mobileDetailsItem}`}>$15 per month if balance falls below $5,000</div>
              <div className={`${styles.mobileDetailsItem} text-center`}>
                <Link to="/business-banking/business-checking-account/interest-checking" className="btn btn-link">
                  Learn More
                </Link>
              </div>
            </div>
          </Collapse>
          <Button {...bizInterestCheckingBtn} />
        </article>

        {/* Business Analyzed Checking */}
        <article id="m-analyzed-checking" className="mb-3 border-bottom">
          <h2>
            <strong>Business Analyzed</strong> Checking
          </h2>
          <div
            id="business-analyzed-toggle"
            onClick={() => {
              setCollapseClass3(!collapseClass3);
            }}
            className={`${styles.mobileDetails} ${
              collapseClass3 ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-label="Show/Hide Details"
            aria-controls="business-analyzed-details"
            aria-expanded={collapseClass3}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapseClass3}>
            <div id="business-analyzed-details" className="collapse">
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>
                Analyzed Checking provides flexibility and control. Design a package tailored to your business with the
                services you need.
              </div>
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>$100 initial deposit</div>
              <div className={`${styles.mobileDetailsItem}`}>
                Fee based on transaction volume. Use your account balance to offset fees.
              </div>
            </div>
          </Collapse>
          <Button {...locationsBtnData} />
        </article>
        <div className={`${styles.mobileDetailsItem}`}>
          Please reach out to a <Link to="/locations">branch</Link>,{" "}
          <Link to="/business-banking/near-me">Banking Officer</Link> or{" "}
          <Link to="/commercial-banking/commercial-banker-near-me">Commercial Banker</Link> for additional information.
        </div>
      </div>
    </section>
  );
};
export default BusinessCheckingOverview;
